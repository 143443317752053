import { ReactComponent as Horizontalrule } from '../../assets/images/horizontal_rule.svg';

function Text() {
  return (
    <div>
      <div>We are encouraged and inspired by our clients who share project results with us</div>
    </div>
  );
}

function Stats() {
  return (
    <div className='bg-[#f5f5f5] '>
      <div className='mx-auto px-5 py-10 sm:py-20 sm:max-w-[93.75%]  md:max-w-[92.22%]'>
        <div className='md:grid md:grid-cols-2 sm:grid-cols-2 sm:gap-10 w-full grid justify-between grid-cols-1'>
          <div className='w-full sm:max-w-[522px]'>
            <img src='/images/Rectangle.jpg' alt='Ball' className='rounded-3xl w-full opac' />
          </div>
          <div className=' mt-4 md:mt-0'>
            <h1 className='text-green-500 text-lg md:text-xl sm:text-xl md:ml-0  font-medium'>
              Stats
            </h1>
            <h1 className='text-lg sm:text-2xl md:text-3xl font-inter font-semibold text-gray-800 mt-2'>
              <Text />
            </h1>
            <div className='grid md:flex sm:flex mt-20'>
              <div className='flex'>
                <h1 className='font-inter md:text-4xl text-4xl sm:text-2xl font-medium text-gray-600'>
                  4.4/5
                </h1>
                <div className='ml-1'>
                  <p className='font-medium text-gray-600 md:text-xs text-sm font-inter'>Average</p>
                  <p className='font-medium text-gray-600 md:text-xs text-sm font-inter'>
                    client rating
                  </p>
                </div>
              </div>
              <div className='flex ml-0 my-4 md:my-0 md:ml-12 sm:-mt-1 sm:text-2xl sm:ml-6 md:-mt-1 '>
                <h1 className='md:text-4xl text-4xl font-medium text-gray-600 sm:text-2xl'>29+</h1>
                <div className='ave'>
                  <p className='font-medium text-sm text-gray-600 md:text-xs font-inter'>
                    Completed
                  </p>
                  <p className='font-medium text-sm text-gray-600 md:text-xs font-inter'>
                    Projects
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden sm:block mt-20'>
          <Horizontalrule className='w-full' />
        </div>
      </div>
    </div>
  );
}

export default Stats;
