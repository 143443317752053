import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { ReactComponent as CloseLine } from '../../assets/images/close.svg';
import { ReactComponent as MenuLine } from '../../assets/images/menu.svg';
import { NAV_LINK_ITEMS as navitems } from '../constants';

function Navbar({ objectRefs }) {
  const navbarRef = useRef(null);
  const hamburgerRef = useRef(null);

  const srollToSection = elementRef => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth'
    });
  };

  const handleToggleNavbar = () => {
    navbarRef.current.classList.toggle('show');
    hamburgerRef.current.classList.toggle('open');
  };

  const handleSCrollAndNav = arg => {
    srollToSection(arg);
    handleToggleNavbar();
  };
  return (
    <header className=''>
      <nav className='navbar-container'>
        <div className='flex items-center'>
          <Link to='/'>
            <Logo />
          </Link>
        </div>
        <div className='flex'>
          <div className='backdrop-wrapper' ref={navbarRef}>
            <ul className='items-wrapper'>
              {navitems.map((navitem, i) => {
                if (
                  navitem !== navitems[navitems.length - 1] &&
                  navitem !== navitems[navitems.length - 3]
                ) {
                  return (
                    <li
                      className='text-sm leading-[19px] font-semibold cursor-pointer'
                      key={i}
                      onClick={() =>
                        handleSCrollAndNav(objectRefs[navitem.toLowerCase().split(' ')[0]])
                      }
                    >
                      {navitem}
                    </li>
                  );
                }
                if (navitem !== navitems[navitems.length - 1]) {
                  return (
                    <>
                    <li className='text-sm leading-[19px] font-semibold hidden md:block' key={i}>
                      <Link
                        onClick={handleToggleNavbar}
                        to='/portfolio'
                        className='text-white hover:text-white'
                      >
                        {navitem}
                      </Link>
                    </li>
                    <li className='text-sm leading-[19px] font-semibold md:hidden' key={i}>
                      <a
                        onClick={handleToggleNavbar}
                        href='https://drive.google.com/file/d/1ALYJ1bXrETTa7xVuER8O7Jg9167rpxpV/view?usp=sharing'
                        target='_blank'
                        rel='noreferrer'
                        className='text-white hover:text-white'
                      >
                        {navitem}
                      </a>
                    </li>
                    </>
                  );
                }
                return (
                  <button
                    key={i}
                    onClick={() =>
                      handleSCrollAndNav(objectRefs[navitem.toLowerCase().split(' ')[0]])
                    }
                    className='bg-[#25AA4A] font-bold text-lg cursor-pointer leading-[22px] rounded-[16px] shadow-sm py-2.5 px-5'
                    type='button'
                  >
                    {navitem}
                  </button>
                );
              })}
            </ul>
          </div>
          {/* mobile toggle */}
          <button
            type='button'
            ref={hamburgerRef}
            onClick={handleToggleNavbar}
            className='sm:hidden flex items-center'
          >
            <CloseLine className='close-btn' />
            <MenuLine className='open-btn' />
          </button>
        </div>
      </nav>
    </header>
  );
}
export default Navbar;

Navbar.propTypes = {
  objectRefs: PropTypes.any
};
