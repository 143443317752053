import { TESTIMONIALS_OBJECT as testimonials } from '../constants';
import { ReactComponent as Horizontal_rule } from '../../assets/images/horizontal_rule.svg';

function Testimonials() {
  return (
    <section className='bg-[#F5F5F5] px-6 '>
      <div className='grid gap-[20px] mx-auto sm:max-w-[93.75%]  md:max-w-[92.22%] content-between font-[inter] justify-center  pt-0 pb-[100px] sm:pt-[50px] '>
        <h2 className='text-[12px] text-center font-semibold leading-4 sm:text-xl md:leading-6 text-[#25AA4A]'>Client Testimonials</h2>
        <div className='grid gap-12 pt-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 '>
          {testimonials.map((item) => (
            <div key={item.author} className='grid gap-6 p-6 sm:gap-5 rounded-lg bg-[#e6e5e5b7] shadow-md justify-between justify-items-center'>
              <img className='h-20 w-20 rounded-full' src={item.img} alt={item.author} />
              <q className='text-[#494949] text-center text-base italic justify-items-center'>{item.paragraph}</q>
              <div className='grid justify-items-center'>
                <p className='text-[#A6A6A6]'>{item.author}</p>
                <p className='text-[#494949] text-base font-semibold'>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='w-full'>
        <Horizontal_rule className='hidden sm:block mx-auto w-full sm:max-w-[93.75%]  md:max-w-[92.22%]' />
      </div>
    </section>
  );
}
export default Testimonials;
