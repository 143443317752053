import { FOOTER_OBJECTS as footer_items } from 'components/constants';
import { ReactComponent as Horizontalrule } from '../../assets/images/horizontal_rule.svg';
import PropTypes from 'prop-types';

const Footer = ({ contact }) => {
  return (
    <footer className='footer-container' ref={contact}>
      <div className='footer-wrapper'>
        <div className='contact-container'>
          <div className='flex flex-col place-content-center'>
            <div className='flex flex-col  gap-5 md:gap-6'>
              <h2 className='contact-heading'>{footer_items.title}</h2>
              <p className='contact-paragraph font-inter'>{footer_items.heading}</p>
              <div>
                <p className='text-white text-base'>
                  {footer_items.email}{' '}
                  <span>
                    <a
                      href='https://airtable.com/shrTLQCqh3XCHjoAO'
                      target='_blank'
                      rel='noreferrer'
                      className='uppercase text-[#25AA4A] hover:underline font-semibold hover:text-[#25AA4A]'
                    >
                      {footer_items.form}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${footer_items.illustration_overlay})` }}
          className='contact-illustaration-bg'
        ></div>
      </div>
      <div className='w-full'>
        <Horizontalrule className='w-full mx-auto sm:max-w-[93.75%]  md:max-w-[92.22%]' />
      </div>
      <div className='footer-contacts'>
        <div className='footer-contact-wrapper'>
          <footer_items.text_logo />
          <address className='footer-contact-text'>{footer_items.address}</address>
          <a href={`tel:${footer_items.tel}`} className='footer-contact-text hover:text-[#A6A6A6]'>
            {footer_items.tel}
          </a>
        </div>
        <p className='footer-contact-text'>&copy;{` ${footer_items.copyright}`}</p>
      </div>
    </footer>
  );
};
export default Footer;

Footer.propTypes = {
  contact: PropTypes.any
};
