// import { LEVERAGE_DIGITAL } from 'components/constants';
import { ReactComponent as Fairmoney } from '../../assets/images/renfairtech.svg';
import { ReactComponent as Spiral } from '../../assets/images/spiral.svg';
import love_image from '../../assets/images/love_image.jpg';
import { ReactComponent as Background } from '../../assets/images/background.svg';
import { ReactComponent as Creative } from '../../assets/images/creative.svg';
import { ReactComponent as Growth } from '../../assets/images/growth_image.svg';
import { ReactComponent as Insights } from '../../assets/images/insights.svg';

function Text() {
  return (
    <div>
      <div className='font-inter'>Helping brands build </div>
      <div className='font-inter'>relevance and own their</div>
      <div className='font-inter'>Industry</div>
    </div>
  );
}
function HeaderOne() {
  return (
    <div className='Landing_page pt-20 overflow-x-hidden'>
      <div className='mx-auto px-6 sm:max-w-[93.75%]  md:max-w-[92.22%]'>
        <div className='grid md:grid-cols-2 content-center gap-x-20 sm:pt-20 md:pt-32 relative'>
          <div className=''>
            <h1 className='text-white font-medium text-xl sm:text-2xl lg:text-4xl md:text-3xl font-sans mt-1 md:mt-8'>
              <Text />
            </h1>
            <div className='grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid md:grid-cols-2 gap-3'>
              <p className='text-base sm:text-lg md:text-xl md:font-medium font-normal sm:font-normal text-white mt-5 md:mt-10 font-inter'>
                Leveraging digital tools and strategic integrated marketing expertise to grow brand
                image and revenue.
              </p>
              <p className='hidden sm:block text-lg sm:text-sm sm:font-normal md:text-sm font-medium text-white mt-10 md:mt-10 opac'>
                Achieving optimal results using a perfect blend of targeted advertising, PR, SEO
                auditing/keyword ranking and social media optimization.
              </p>
            </div>
            <div className='pt-10 relative flex flex-col'>
              <div className='grid grid-cols-2'>
                <div>
                  <a href='https://airtable.com/shrTLQCqh3XCHjoAO' target='_blank' rel='noreferrer'>
                    <button className='bg-white text-base sm:text-lg md:text-xl py-3 px-5 sm:px-10 md:px-8 text-green-500 rounded-2xl'>
                      Get Started
                    </button>
                  </a>
                  <div className='h-40 sm:h-30'></div>
                </div>
                <div className=''>
                  <Spiral className='hidden md:block' />
                  <Spiral className='block sm:hidden' />
                </div>
              </div>
              <div className='hidden sm:flex items-center absolute bottom-[20%] sm:bottom-[10%]'>
                <Fairmoney />
              </div>
            </div>
          </div>
          <div className='opac opa absolute top-10 right-[5%] hidden md:block'>
            <figure className='grid grid-cols-1 grid-rows-1 p-6'>
              <div className='flex flex-wrap max-w-[404.36px] z-10 gap-7 relative top-6 row-span-full col-span-full'>
                <div className='w-[168.18px] h-[256px]'>
                  <div className='h-10 w-full'></div>
                  <div>
                    <Insights className='w-full h-full' />
                  </div>
                </div>
                <div className='flex'>
                  <div className='w-[168.18px] h-[216px]'>
                    <img src={love_image} className='w-full h-full rounded-xl' />
                  </div>
                  <div className='h-full w-10'></div>
                </div>
                <div className=' h-[216px] flex'>
                  <div className='h-full w-10'></div>
                  <div>
                    <Creative className='w-full h-full' />
                  </div>
                </div>
                <div className='w-[168.18px] absolute top-[49%] left-[59%]'>
                  <div>
                    <Growth className='w-full h-full' />
                  </div>
                  <div className='h-10 w-full'></div>
                </div>
              </div>
              <Background className='ml-16 absolute ' />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderOne;
