import PropTypes from 'prop-types';

function CroissanceButton({ text, color, height, width, icon, className }) {
  return (
    <button
      color={color}
      height={height}
      width={width}
      icon={icon}
      className={`rounded-lg ${className}`}
    >
      {text}
    </button>
  );
}

export default CroissanceButton;

CroissanceButton.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string
};
