/* eslint-disable react/button-has-type */
// import React from 'react';
import { ReactComponent as Horizontal_rule } from '../../assets/images/horizontal_rule.svg';
import { ReactComponent as Exponential } from '../../assets/images/exponential.svg';
import { ReactComponent as Rating } from '../../assets/images/customer_rating.svg';
import { ReactComponent as Growth } from '../../assets/images/growth_mindset.svg';
import { BsArrowUpRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Leadership() {
  return (
    <div>
      <div>
        We utilize a data driven approach to execute market manipulation while leveraging trends and
        thought leadership processes that pave the way for social capitalization.
      </div>
    </div>
  );
}
function Under() {
  return (
    <div>
      <div>
        Under our belt are powerful tools that have helped optimize our processes in a steel tight
        lock on standard for optimization. This keeps us two steps ahead of the trends at all times.
      </div>
    </div>
  );
}
function Croissance() {
  return (
    <div>
      <div>
        At Croissance, we possess a deep understanding of the behavioral analytics of consumers.
        Why, what and when people buy is a huge part of our research approach and has quickly formed
        a foundation as to why our clients achieve success.
      </div>
    </div>
  );
}
function WhyCroissance({ whyCroissance }) {
  return (
    <div className='bg-[#f5f5f5] pt-16 ' ref={whyCroissance}>
      <div className='mx-auto px-6 py-10 sm:max-w-[93.75%]  md:max-w-[92.22%]'>
        <h1 className='text-green-500 text-sm sm:text-xl font-medium'>Why Croissance</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
          <div className='one'>
            <h1 className='text-lg sm:text-3xl md:text-4xl font-semibold mt-2 text-[#494949] max-w-lg font-inter'>
              Data driven solutions for organizations who aspire to be more
            </h1>
            <Link
             to={'/portfolio'}
            >
              <button className='text-white px-16 md:py-4 my-8 hidden md:flex md:pr-16 md:pl-6 sm:py-3 sm:px-12 py-5 font-semibold text-xs md:text-lg sm:text-xl bg-black rounded-2xl shadow-xl'>
                View our Portfolio{' '}
                <span className='mt-1 ml-2 font-semibold text-lg'>
                  <BsArrowUpRight className='' />
                </span>
              </button>
            </Link>
            <img src='/images/Cap.jpg' alt='' className='md:block hidden -pt-10 sm:hidden' />
          </div>
          <div className='why-croissance-main-wrapper'>
            <div className='why-croissance-sub-wrapper'>
              <div>
                <Rating />
              </div>
              <div className='why-croissance-description-wrapper'>
                <h3 className='font-medium text-gray-600 text-base'>Understanding the Customer</h3>
                <p className='font-normal text-sm sm:text-base'>
                  <Croissance />
                </p>
              </div>
            </div>
            <div className='why-croissance-sub-wrapper'>
              <div>
                <Growth />
              </div>
              <div className='why-croissance-description-wrapper'>
                <h3 className='font-medium text-gray-600 text-base'>Growth Mindset</h3>
                <p className='font-normal text-sm sm:text-base'>
                  <Leadership />
                </p>
              </div>
            </div>
            <div className='why-croissance-sub-wrapper'>
              <div>
                <Exponential />
              </div>
              <div className='why-croissance-description-wrapper'>
                <h3 className='font-medium text-gray-600 text-base'>Exponential Technologies</h3>
                <p className='font-normal text-sm sm:text-base'>
                  <Under />
                </p>
              </div>
            </div>
          </div>
          <a
            href='https://drive.google.com/file/d/1ALYJ1bXrETTa7xVuER8O7Jg9167rpxpV/view?usp=sharing'
            target='_blank'
            rel='noreferrer'
          >
            <button className='text-white md:hidden mt-16 max-w-[244px] h-[43px] font-bold text-[14px] bg-black rounded-2xl flex items-center shadow-xl px-5'>
              View our Portfolio{' '}
              <span className='font-bold text-lg ml-3 mt-1'>
                <BsArrowUpRight className='' />
              </span>
            </button>
          </a>
        </div>
      </div>
      <div className='w-full hidden mx-auto sm:block mt-[20px]'>
        <Horizontal_rule className='w-full' />
      </div>
    </div>
  );
}

export default WhyCroissance;

WhyCroissance.propTypes = {
  whyCroissance: PropTypes.any
};
