import { Layout } from 'antd';
import PropTypes from 'prop-types';
import Navbar from 'components/common/Navbar';
import Ourservices from 'components/sub_hero/Ourservices';
import Stats from 'components/LandingPage/Stats';
import WhyCroissance from 'components/LandingPage/WhyCroissance';
import Footer from 'components/footer/Footer';
import Testimonials from 'components/testimonials/Testimonials';
import { useRef } from 'react';
import HeaderOne from 'components/LandingPage/HeaderOne';
import Loading from 'components/common/Loading';

function Home() {
  const services = useRef(null);
  const whyCroissance = useRef(null);
  const contact = useRef(null);
  const objectRefs = {
    services: services,
    why: whyCroissance,
    contact: contact
  };
  return (
    <Layout>
      <Loading />
      <Navbar objectRefs={objectRefs} />
      <HeaderOne />
      <Ourservices services={services} />
      <WhyCroissance whyCroissance={whyCroissance} />
      <Stats />
      <Testimonials />
      <Footer contact={contact} />
    </Layout>
  );
}

export default Home;
Home.propTypes = {
  children: PropTypes.any
};
