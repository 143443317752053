import { ReactComponent as Logoanimation } from '../../assets/images/logoanimation.svg';
import { useEffect } from 'react';
import { useState } from 'react';
const Loading = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeOut);
  }, []);
  return (
    loading && (
      <div
        className='bg-black h-screen fixed flex items-center justify-center loader z-[9999] w-full'
        id='loader'
      >
        <div className='w-[100px] h-[100px]'>
          <Logoanimation className='w-full h-full' />
        </div>
      </div>
    )
  );
};
export default Loading;
