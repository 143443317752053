import { ReactComponent as Advertisement } from '../assets/images/advertisement.svg';
import { ReactComponent as Growth } from '../assets/images/growth.svg';
import { ReactComponent as Paint } from '../assets/images/paint.svg';
import { ReactComponent as Analytics } from '../assets/images/analytics.svg';

// PORTFOLIO IMPORTS
import { ReactComponent as Portfolio_icon } from '../assets/images/portfolio_icon.svg';
import portfolio_staff from '../assets/images/portfolio_staff_web.jpeg';
import portfolio_phone from '../assets/images/portfolio_phone_web.jpeg';
import portfolio_building from '../assets/images/portfolio_business_building_web.jpeg';
import portfolio_worker from '../assets/images/portfolio_worker_web.jpeg';
import portfolio_touchpoints from '../assets/images/portfolio_touchpoints_web.jpeg';

// FOOTER IMPORTS

import Illustration_overlay from '../assets/images/footer_illustration_overlay.png';
import { ReactComponent as Text_logo } from '../assets/images/text_logo.svg';

// TESTIMONIALS IMPORTS

import nonso_image from '../assets/images/nonso_image.gif';
// import thomas_image from '../assets/images/image-thomas.jpg';
// import jennie_image from '../assets/images/image-jennie.jpg';
// import image_placeholder from '../assets/images/image-place-holder.png';

// LEVERAGE EXPORTS
export const LEVERAGE_DIGITAL = 'Leverage digital';

// NAVBAR EXPORTS
export const NAV_LINK_ITEMS = ['Why Croissance', 'Portfolio', 'Services', 'Contact Us'];

// OUR SERVICES EXPORTS
export const OUR_SERVICES_SECTION = {
  title: 'Our Services',
  heading:
    'We drive sustainable growth for companies by increasing their competitive advantage through our service offerings',
  cta: 'Contact Croissance',
  infos: [
    {
      icon: Advertisement,
      name: 'Digital Advertising',
      text: 'We drive digital advertising campaigns with a hard focus on growth',
      background: '#25AA4A',
      shadow: '0px 30px 25px rgba(37, 170, 74, 0.4)'
    },
    {
      icon: Paint,
      name: 'Creative Executions',
      text: 'We draw insights that drive our creative execution leading to awe-inspiring renders.',
      background: '#1E74F5',
      shadow: '0px 30px 25px rgba(30, 116, 245, 0.4)'
    },
    {
      icon: Growth,
      name: 'Growth Marketing',
      text: 'We drive growth for companies that increase MRR and reduce customer acquisition costs.',
      background: '#F2A50F',
      shadow: '0px 30px 25px rgba(242, 165, 15, 0.4)'
    },
    {
      icon: Analytics,
      name: 'Analytics & Automation',
      text: 'We provide in-depth data to equip brands in taking better marketing initiatives.',
      background: '#9747FF',
      shadow: '0px 30px 25px rgba(151, 71, 255, 0.4)'
    }
  ]
};

// PORTFOLIO EXPORTS
export const PORTFOLIO_OBJECTS = {
  title: 'Portfolio',
  heading: 'Screenshots from our latest projects'
};

export const PORTFOLIO_IMAGES = {
  staff: {
    img_src: portfolio_staff,
    img_alt: 'happy workers',
    img_title: 'Indepth consumer analysis for Technooil',
    icon: Portfolio_icon,
    icon_text: '+50% Consumer target expansion',
    width: '630px',
    height: '517px'
  },

  touchpoints: {
    img_src: portfolio_touchpoints,
    img_alt: 'branding goals',
    width: '630px',
    height: '335px'
  },
  worker: {
    img_src: portfolio_worker,
    img_alt: 'dedicated staff',
    width: '630px',
    height: '249px'
  },
  building: {
    img_src: portfolio_building,
    img_alt: 'four building shot from an elevation',
    width: '305px',
    height: '440px'
  },
  phone: {
    img_src: portfolio_phone,
    img_alt: 'phone',
    width: '305px',
    height: '440px'
  }
};

// FOOTER OBJECTS EXPORTS

export const FOOTER_OBJECTS = {
  title: 'Contact Us',
  heading: 'Have a project in mind? Let’s get to work.',
  email: 'Send an email to hello@croissance.ng or fill this',
  form: 'form',
  illustration_overlay: Illustration_overlay,
  text_logo: Text_logo,
  address: '10 Hughes Avenue, Opposite Westerfield College, Alagomeji, Yaba, Lagos.',
  tel: '+2348057611109',
  copyright: new Date().getFullYear()
};

// TESTIMONIALS EXPORTS
// Chisom Okafor, Brand Manager, FairMoney
export const TESTIMONIALS_OBJECT = [
  {
    img: nonso_image,
    paragraph:
      'The value provided by Croissance digital meets my expectations and I’m very satisfied. An area that stood out for me is that quick turnaround on requests, despite short notices. The team is also accessible and very responsive.',
    author: ' Nonso N.',
    title: 'PR and Events Specialist, FairMoney'
  },
  {
    img: nonso_image,
    paragraph:
      'Incredible end result! Our sales increased over 400% when we worked with Croissance. Highly recommended!',
    author: 'Jennie F.',
    title: 'Business Owner'
  },
  {
    img: nonso_image,
    paragraph:
      '“Working with Croissance is like working with family. They always come through when needed.”',
    author: 'Chisom Okafor',
    title: 'Brand Manager, FairMoney'
  }
];
