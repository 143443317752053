import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/images/Logo.svg';

const PortFolio = () => {
  return (
    <div>
      <nav className=' bg-backgroundColorBlack'>
        <div className='container mx-auto px-5 py-2'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>

            <Link to='/' className='bg-[#25AA4A] font-bold text-lg cursor-pointer leading-[22px] rounded-[16px] shadow-sm py-2.5 px-5 text-white'>
              Back To Home
            </Link>
          </div>
        </div>
      </nav>

      <iframe src={'https://firebasestorage.googleapis.com/v0/b/croissance-e4250.appspot.com/o/Croissance-portfolio-2023.pdf?alt=media&token=10b05e27-d1f9-46f2-b4b6-bd335bddede3'} width='100%' height={500} style={{ height: '95vh' }} />
    </div>
  );
};

export default PortFolio;
