import CroissanceButton from '../common/Button';
import PropTypes from 'prop-types';
import { ReactComponent as Horizontalrule } from '../../assets/images/horizontal_rule.svg';
import { OUR_SERVICES_SECTION as servicesItems } from 'components/constants';

const Ourservices = ({ services }) => {
  return (
    <section className='font-[inter] bg-[#f5f5f5]' ref={services}>
      <div className='mx-auto px-6 sm:max-w-[93.75%] md:max-w-[92.22%]'>
        <div className='flex flex-wrap justify-between pt-[50px] md:pt-[100px] pb-[80px] gap-4 md:pb-[50px] items-center'>
          <div className='font-semibold flex flex-col gap-3 md:gap-5 max-w-[264px] md:max-w-[522px]'>
            <h2 className='text-[12px] leading-4 md:text-xl md:leading-6 text-[#25AA4A]'>
              {servicesItems.title}
            </h2>
            <p className='text-[#494949] leading-6 md:leading-10 text-[16px] md:text-[32px] font-inter'>
              {servicesItems.heading}
            </p>
          </div>
          <a href='https://airtable.com/shrTLQCqh3XCHjoAO' target='_blank' rel='noreferrer'>
            <CroissanceButton
              text={servicesItems.cta}
              className='bg-[#282828] md:rounded-2xl active:scale-[.98] transition-all hover:bg-[#383838] shadow-lg shadow-[#838383] leading-4 md:leading-5 text-white px-7 py-3 sm:py-5 text-sm sm:text-lg font-inter md:text-[18px] font-bold'
            />
          </a>
        </div>
        <div className='grid max-w-[90%] sm:max-w-[100%] mx-auto subhero-grid-template gap-7 md:gap-4 pb-14'>
          {servicesItems.infos.map((infoItem, i) => {
            return (
              <div
                style={{ background: `${infoItem.background}`, boxShadow: `${infoItem.shadow}` }}
                key={i}
                className='py-[22px] px-5 xs:px-[23px]  rounded-lg flex flex-col gap-4 text-white'
              >
                <div className='flex items-center gap-3 w-full'>
                  <infoItem.icon />
                  <h3 className='mt-2 xs:mt-0 text-white font-semibold text-lg leading-6'>
                    {infoItem.name}
                  </h3>
                </div>
                <p className='text-base font-normal'>{infoItem.text}</p>
              </div>
            );
          })}
        </div>
        <div className='hidden sm:block mt-20'>
          <Horizontalrule className='w-full' />
        </div>
      </div>
    </section>
  );
};
export default Ourservices;
Ourservices.propTypes = {
  services: PropTypes.any
};
